import React from "react";
import styled from "styled-components";
import { H4 } from "melodies-source/Text";
import { LoadingScreenProps } from ".";

export const SpinnerRing: React.FC<LoadingScreenProps & { color?: string }> = ({
  icon,
  message,
  color,
  ...props
}) => {
  return (
    <LoadingContainer {...props}>
      <SpinnerContainer>
        <IconContainer>{icon}</IconContainer>
        <Svg
          width="170"
          height="170"
          viewBox="0 0 200 200"
          color={color ?? "#1B0076"}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <linearGradient id="spinner-secondHalf">
              <stop offset="0%" stopOpacity="0" stopColor="currentColor" />
              <stop offset="100%" stopOpacity="0.5" stopColor="currentColor" />
            </linearGradient>
            <linearGradient id="spinner-firstHalf">
              <stop offset="0%" stopOpacity="1" stopColor="currentColor" />
              <stop offset="100%" stopOpacity="0.5" stopColor="currentColor" />
            </linearGradient>
          </defs>

          <g strokeWidth="8">
            <path
              stroke="url(#spinner-secondHalf)"
              d="M 4 100 A 96 96 0 0 1 196 100"
            />
            <path
              stroke="url(#spinner-firstHalf)"
              d="M 196 100 A 96 96 0 0 1 4 100"
            />

            <path
              stroke="currentColor"
              strokeLinecap="round"
              d="M 4 100 A 96 96 0 0 1 4 98"
            />
          </g>
          <animateTransform
            from="0 0 0"
            to="360 0 0"
            attributeName="transform"
            type="rotate"
            repeatCount="indefinite"
            dur="1300ms"
          />
        </Svg>
      </SpinnerContainer>
      {message && <H4>{message}</H4>}
    </LoadingContainer>
  );
};

const Svg = styled.svg`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
`;

const LoadingContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  aspect-ratio: 1;
  align-items: center;
  justify-content: center;
  margin: auto;
`;

export const SpinnerContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 220px;
  align-items: center;
  justify-content: center;
`;

export const IconContainer = styled.div`
  margin-top: 10px;
  svg {
    width: 80px;
    height: 80px;
    color: #1b0076;
  }
`;

import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { Dashboard } from "./Dashboard";
import { Fans } from "./Fans";
import { Settings } from "./Settings";
import STSReport from "./STSReport";
import { RouteComponentProps } from "react-router-dom";
import { Reporting } from "./Reporting";
import { SetLive } from "./SetLive";
import { ShowDetails } from "./SetLive/ShowDetails";
import { ArtistEventController } from "contexts/ArtistEventContext";
import { HelpCenter } from "./HelpCenter";
import { ArtistProvider, DefaultLayout, MenuBar } from "Components";
import { QrCodeModal } from "./Settings/Components/ArtistQrCode/QrCodeModal";
import { useDialog } from "Components/DialogProvider";
import { SetFan } from "./SetFan";
import { TypesenseProxyProvider } from "contexts/TypesenseProxyContext";
import { useLayoutEffect, lazy, Suspense, PropsWithChildren } from "react";
import { useUser } from "auth";
import { QueryClient, QueryClientProvider } from "react-query";
import { GlobalStyles } from "styled";

const SetPageEditorRoute = lazy(() => import("./SetPage"));
const SetPageHomeRoute = lazy(() => import("./SetPageHome"));
const SetPageWizardRoute = lazy(() => import("./SetPageWizard"));

type Props = RouteComponentProps<{ artistId: string }>;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const SetPageLayout = ({ children }: PropsWithChildren<{}>) => {
  const location = useLocation();

  if (location.pathname.includes("set-bio/wizard")) {
    return (
      <>
        <MenuBar />
        {children}
      </>
    );
  }

  return (
    <DefaultLayout>
      <GlobalStyles grayBG />
      {children}
    </DefaultLayout>
  );
};

const SetPageRoutes = () => {
  const match = useRouteMatch();

  return (
    <QueryClientProvider client={queryClient}>
      <SetPageLayout>
        <Suspense fallback={<></>}>
          <Switch>
            <Route
              path={match.path}
              component={SetPageHomeRoute}
              exact={true}
            />
            <Route
              path={`${match.path}/wizard`}
              component={SetPageWizardRoute}
              exact={true}
            />
            <Route
              path={`${match.path}/editor`}
              component={SetPageEditorRoute}
              exact={true}
            />
          </Switch>
        </Suspense>
      </SetPageLayout>
    </QueryClientProvider>
  );
};

export const Routes = ({ match }: Props) => {
  const { dialog } = useDialog();
  const location = useLocation();
  const { claims } = useUser();

  useLayoutEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location.pathname]);

  return (
    <ArtistProvider>
      <TypesenseProxyProvider>
        {dialog?.qrCode && <QrCodeModal />}
        <Switch>
          <Route
            path={`${match?.path}/reporting/set-live/shows/:eventId`}
            render={(props) => (
              <ArtistEventController eventId={props.match.params.eventId}>
                <ShowDetails />
              </ArtistEventController>
            )}
          />
          <Route
            path={`${match?.path}/set-live/:tabParam`}
            component={SetLive}
          />
          <Route path={`${match?.path}/set-fan`} component={SetFan} />
          {claims.admin && (
            <Route path={`${match?.path}/set-bio`} component={SetPageRoutes} />
          )}
          <Redirect
            exact
            from={`${match?.path}/set-live`}
            to={`${match?.path}/set-live/upcoming-shows`}
          />
          <Route path={`${match?.path}/help-center`} component={HelpCenter} />
          <Route
            path={`${match?.path}/fans`}
            render={(props) => <Fans match={props.match} />}
          />
          <Route
            path={`${match?.path}/reporting/:tabParam`}
            component={Reporting}
          />
          <Redirect
            exact
            from={`${match?.path}/reporting`}
            to={`${match?.path}/reporting/set-live`}
          />
          <Route path={`${match?.path}/settings`} component={Settings} />
          <Route path={`${match?.path}/sts-report`} component={STSReport} />
          <Route path={`${match?.path}/`} component={Dashboard} />
        </Switch>
      </TypesenseProxyProvider>
    </ArtistProvider>
  );
};

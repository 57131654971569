import { ConcertData, useUpcomingConcerts } from "hooks/useUpcomingConcerts";
import { Link } from "melodies-source/Link";
import { Modal, ModalContainer, ModalWrapper } from "melodies-source/Modal";
import { Body1 } from "melodies-source/Text";
import { addToast } from "melodies-source/Toast";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { ConcertDetails } from "Routes/SetLive/Duplicate/types";
import { SettingsRow } from "../SettingsRow";
import { DeleteConcert } from "./DeleteConcert";
import { EditConcert } from "./EditConcert";
import { useBulkCreateConcerts } from "./hooks/useBulkCreateConcerts";
import { useCreateConcert } from "./hooks/useCreateConcert";
import { useDeleteConcert } from "./hooks/useDeleteConcert";
import { useEditConcert } from "./hooks/useEditConcert";
import { useReplaceConcert } from "./hooks/useReplaceConcert";
import { useSetLiveBuilderEvents } from "./hooks/useSetLiveBuilderEvents";
import { ListConcerts } from "./ListConcerts";
import { ReplaceConcert } from "./ReplaceConcert";
import { getEvent } from "./utils";
import { AddConcerts } from "Components";
import styled from "styled-components";
import { useIsMobile } from "melodies-source/utils";

enum Type {
  List,
  Edit,
  Create,
  Delete,
  Replace,
}

type State =
  | { type: Type.List }
  | { type: Type.Edit; id: string }
  | { type: Type.Create; list?: true }
  | { type: Type.Delete; id: string }
  | { type: Type.Replace; data: ConcertDetails<Date> | ConcertData };

export const getConcert = (state: State, concerts: ConcertData[] = []) => {
  return state && "id" in state
    ? concerts?.find(({ id }) => id === state.id)
    : undefined;
};

export const ArtistConcerts = () => {
  const { artistId } = useParams<{ artistId: string }>();
  const [state, setState] = useState<State>(null);
  const [concerts] = useUpcomingConcerts(artistId);
  const [events] = useSetLiveBuilderEvents(artistId);
  const isMobile = useIsMobile();

  const concert = getConcert(state, concerts);
  const event = getEvent(concert, events);

  const createConcert = useCreateConcert({
    artistId,
    concerts,
    onCollision: (data) => {
      setState({ type: Type.Replace, data });
    },
    onSuccess: () => {
      setState({ type: Type.List });
      addToast("Show added successfully", "success");
    },
  });

  const bulkCreateConcerts = useBulkCreateConcerts({
    artistId,
    onSuccess: () => {
      setState({ type: Type.List });
      addToast("Shows added successfully", "success");
    },
  });

  const editConcert = useEditConcert({
    concerts,
    events,
    onCollision: (data) => {
      setState({ type: Type.Replace, data });
    },
    onSuccess: () => {
      if (state.type === Type.Replace) {
        setState({ type: Type.List });
      }
    },
  });

  const deleteConcert = useDeleteConcert({
    onSuccess: () => {
      setState({ type: Type.List });
      addToast("Your show was deleted successfully.", "success");
    },
  });

  const replaceConcert = useReplaceConcert({
    artistId,
    events,
    onSuccess: () => {
      setState({ type: Type.List });
    },
  });

  return (
    <>
      {state?.type === Type.List && (
        <Modal
          header="Upcoming Shows"
          isOpen={true}
          withViewportMaxHeight={true}
          onClose={() => setState(null)}
        >
          <ListConcerts
            concerts={concerts}
            events={events}
            onEdit={({ id }) => setState({ type: Type.Edit, id })}
            onCreate={() => setState({ type: Type.Create, list: true })}
            onClose={() => setState(null)}
          />
        </Modal>
      )}
      {state?.type === Type.Create && (
        <StyledModal
          header="Add Show(s)"
          isOpen={true}
          onClose={() => setState(null)}
          variant="large"
          withViewportMaxHeight={!isMobile}
        >
          <AddConcerts
            concerts={concerts}
            onCancel={() => setState(state.list ? { type: Type.List } : null)}
            onSubmit={(data) => createConcert.execute({ data })}
            onBulkSubmit={(data) => bulkCreateConcerts.execute({ data })}
          />
        </StyledModal>
      )}
      {state?.type === Type.Edit && (
        <Modal header="Edit Show" isOpen={true} onClose={() => setState(null)}>
          {concert && (
            <EditConcert
              concert={concert}
              onDelete={() => setState({ type: Type.Delete, id: state.id })}
              onBack={() => setState({ type: Type.List })}
              onSubmit={(patch) => editConcert.execute({ concert, patch })}
            />
          )}
        </Modal>
      )}
      {state?.type === Type.Delete && (
        <Modal
          header="Delete Show"
          isOpen={true}
          onClose={() => setState(null)}
        >
          {concert && (
            <DeleteConcert
              concert={concert}
              event={event}
              loading={deleteConcert.loading}
              onCancel={() => setState({ type: Type.List })}
              onSubmit={() => deleteConcert.execute({ concert })}
            />
          )}
        </Modal>
      )}
      {state?.type === Type.Replace && (
        <Modal
          header="Event on Same Day"
          isOpen={true}
          onClose={() => setState(null)}
        >
          <ReplaceConcert
            candidate={state.data}
            concerts={concerts}
            keeping={editConcert.loading || createConcert.loading}
            replacing={replaceConcert.loading}
            onKeep={() => {
              if ("ref" in state.data) {
                editConcert.execute({
                  concert: state.data,
                  patch: { date: state.data.date },
                  withCollision: false,
                });
              } else {
                createConcert.execute({
                  data: state.data,
                  withCollision: false,
                });
              }
            }}
            onReplace={(concert) =>
              replaceConcert.execute({ concert, replacement: state.data })
            }
          />
        </Modal>
      )}
      <SettingsRow
        title="Artist Shows"
        description="View and add upcoming shows here. These shows will be available to use for SET.Live."
      >
        {concerts && (
          <Body1>
            {concerts.length} upcoming shows.{" "}
            <Link onClick={() => setState({ type: Type.List })}>View</Link>
          </Body1>
        )}
        <Link onClick={() => setState({ type: Type.Create })}>Add Show(s)</Link>
      </SettingsRow>
    </>
  );
};

const StyledModal = styled(Modal)`
  ${({ theme }) => theme.mediaQueries.desktop} {
    ${ModalContainer} {
      min-height: 560px;
    }
  }

  ${({ theme }) => theme.mediaQueries.mobile} {
    ${ModalWrapper} {
      padding: 0 10px;
    }
  }
`;
